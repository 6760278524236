.auth-form-wrap-home{
    /* max-width: 1000px; */
    padding: 60px 0;
    margin:0 auto;
  }

#requestToSell {
  font-size: 2rem;
  font-style: bold;
  margin: 10px auto;
  padding-left: 25%;
  padding-right: 25%;
  color: white;
  background-color: #0068ff;
}
.css-1izqkxg-MuiPaper-root-MuiCard-root {
  max-width: 100%;
  min-width: 0px !important;
}
