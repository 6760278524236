.request-to-sell-wrap p{
	line-height: 25px;
	color: #000000;
	font-size: 14px;
}
.btn-container {
	display: flex;
	justify-content: space-between;
}

.required-field::before {
	content: "*";
	color: red;
}
#country-select.countryErr {
	border-color: #d32f2f !important;
}

.custom-error {
	letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 14px;
    line-height: 25px;
    color: #d32f2f;
}

.application-section, .tips-section, .required-section h4{
	text-align: center;
}
.application-section, .required-section{
	padding: 50px 0;
}
.request-to-sell-wrap h4{
	font-size: 18px;
	color: #0068ff;
	margin-bottom: 20px;
}
.tips-card{
	height: 100%;
	border:1px solid #707070;
	display: flex;
	align-items: center;
	padding: 10px 20px;
}
.tips-card p{
	font-size: 12px;
	line-height: 16px;
}
.doc-img{
	width: 250px;
}
.required-section{
	text-align: left;
}

.required-section li{
	color:#000000;
	font-size: 14px;
	margin:20px 0;
	position: relative;
	padding-left: 15px;
}
.required-section li:before {
	font-family: "Font Awesome 5 Free";
	content: "\f00c";
	display: inline-block;
	padding-right: 3px;
	vertical-align: middle;
	font-weight: 900;
	position: absolute;
	left:-10px;
	color: #0068FF;
 }
 .request-btn-wrap .btn-secondary{
	 border:none;
		font-size: 12px;
}
.request-btn-wrap{
	text-align: center;
	padding: 20px 0;
	margin-bottom: 30px;
}
.request-btn-wrap .btn{
	min-width: 150px;
	cursor: pointer;
}
.start {
	min-width: 50%;
    min-height: 40px;
    font-size: 16px !important;
    cursor: pointer;
}
.request-two-about-section{
	text-align: center;
}
.request-two-about-card, .request-form-card{
	border:1px solid #0068FF;
	padding: 40px;
	margin: 40px 0;
	position: relative;
}
.request-arrow-icon{
	position: absolute;
	top:0;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #0068FF;
	background: #fff;
	font-size: 20px;
}
.request-form-card label{
	font-size: 12px;
	color: #000000;
	font-weight: 600;
}
.request-form-section.non-active .request-form-card{
	border-color: #707070;
}
.request-form-section.non-active .request-form-card .request-arrow-icon{
	height: 20px;
	width: 20px;
	border-radius: 100%;
	background:  #707070;
}
.blue-btn, .line-btn{
	background: #0068FF;
	border:1px solid #0068FF;
	border-radius: 0;
	color: #fff;
	cursor: pointer;
	margin-right: 10px;
	font-size: 12px;
}
.line-btn{
	background: transparent;
	border:1px solid #707070;
	color: #707070;
	margin-right: 0;
}
.line-btn:hover{
	background: #0068FF;
	color: #fff;
}
.form-control-file{
	display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #707070;
    border-radius: 0.25rem;

}
.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	width: 100%;
  }

  .upload-btn-wrapper .btn {
	color: #fff;
	background-color: #0068FF;
	padding: 8px 20px;
    border-radius: 0px;
    font-size: 12px;
    font-weight: normal;
	border:none;
  }

  .upload-btn-wrapper input[type=file] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
  }
  .upload-btn-wrapper label{
	  display: block;
  }
  .upload-btn-wrapper-content {
	display: block;
    width: 100%;
    padding: 4px 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #707070;
    border-radius: 0.25rem;
  }
  .upload-btn-wrapper-content p{
	margin-left: 10px;
  }
  .classification-active-input{
	  margin-right: 15px;
  }
  .country-dropdown {
	  border: 1px solid #ccc !important;
	  border-radius: 5px !important;
	  width: 94% !important;
	  height: auto !important;
  }
  .phone-wrap select {
	  margin-right: 20px;
	  width: 60px;
  }
  .upload-btn-wrapper-content-wrap .btn-primary{
	font-size: 12px;
	margin-left: 5px;
  }
  .request-form-card h5{
	  color: #000000;
	  margin-bottom: 20px;
  }
  .btn-listing ul li{
	width: 19%;
    margin-right: 10px;
 }
 .btn-listing ul li:last-child{
	 margin-right: 0;
 }
  .btn-listing ul{
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: space-between;
  }
  .add-more-info a{
	  color: #000000;
	  font-size: 12px;
  }
  .request-form-card .form-control{
	height: 38px;
  }
  .btn-listing.current-shipping li{
	width: 15%;
  }
  .warehouse-address-card{
	  border:1px solid #707070;
	  padding: 30px;
	  text-align: center;
  }
  .request-form-card textarea.form-control{
	  height: auto;
  }
@media(max-width:991px){
	.required-section .doc-img{
		width: 100%;
	}
	.required-section .row{
		align-items: center;
	}
	.upload-btn-wrapper-content-wrap{
		flex-flow: column;
	}
	.upload-btn-wrapper-content-wrap .btn-primary{
		margin-top: 10px;
	}
	.btn-listing ul li{
		width:18%;
	}
	.warehouse-address-card{
		padding: 10px;
	}
}
@media(max-width:767px){
	.tips-section .col-md-4{
		margin-bottom: 20px;
	}
	.btn-listing ul li, .btn-listing.current-shipping li{
		width: 100%;
		margin-right: 0;
	}
}
